import 'core/translation';
import 'styles/_global.scss';

import { lazy, Suspense } from 'react';
import { I18nProvider } from 'react-aria';
import { MapProvider } from 'react-map-gl/mapbox';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { config } from 'config';
import { Authenticated } from 'core/auth';
import { Sentry } from 'core/error/components/Sentry';
import { SelectedDispatchersProvider } from 'modules/commissions/hooks/useSelectedDispatchers';
import { Spinner } from 'modules/ui';
import { store } from 'store';

const ErrorPage = lazy(() => import('core/error').then((m) => ({ default: m.ErrorPage })));
const LogoutPage = lazy(() => import('core/auth').then((m) => ({ default: m.LogoutPage })));
const LoginForm = lazy(() => import('core/auth').then((m) => ({ default: m.LoginForm })));
const CommissionsPage = lazy(() =>
    import('modules/commissions/components/CommissionsPage').then((m) => ({ default: m.CommissionsPage })),
);
const CommissionsDetailPage = lazy(() =>
    import('modules/commissions').then((m) => ({ default: m.CommissionsDetailPage })),
);
const CommissionsNewPage = lazy(() => import('modules/commissions').then((m) => ({ default: m.CommissionsNewPage })));
const CommissionsExport = lazy(() => import('modules/commissions').then((m) => ({ default: m.CommissionsExport })));
const CarriersPage = lazy(() => import('modules/carriers').then((m) => ({ default: m.CarriersPage })));
const CarrierDetailPage = lazy(() => import('modules/carriers').then((m) => ({ default: m.CarrierDetailPage })));
const CarriersNewPage = lazy(() => import('modules/carriers').then((m) => ({ default: m.CarriersNewPage })));
const CustomersPage = lazy(() => import('modules/customers').then((m) => ({ default: m.CustomersPage })));
const CustomersDetailPage = lazy(() =>
    import('modules/customers/components/CustomersDetailPage').then((m) => ({ default: m.CustomersDetailPage })),
);
const CustomersNewPage = lazy(() =>
    import('modules/customers/components/CustomersNewPage').then((m) => ({ default: m.CustomersNewPage })),
);
const InvoicingPage = lazy(() => import('modules/invoicing').then((m) => ({ default: m.InvoicingPage })));
const InvoicingDetailPage = lazy(() => import('modules/invoicing').then((m) => ({ default: m.InvoicingDetailPage })));
const InvoicingNewPage = lazy(() =>
    import('modules/invoicing/components/InvoicingNewPage').then((m) => ({ default: m.InvoicingNewPage })),
);
const OffersPage = lazy(() => import('modules/offers').then((m) => ({ default: m.OffersPage })));
const OffersDetailPage = lazy(() => import('modules/offers').then((m) => ({ default: m.OffersDetailPage })));
const DispatcherDetailPage = lazy(() =>
    import('modules/onboarding').then((m) => ({ default: m.DispatcherDetailPage })),
);
const DispatcherNewPage = lazy(() => import('modules/onboarding').then((m) => ({ default: m.DispatcherNewPage })));
const OnboardingPage = lazy(() => import('modules/onboarding').then((m) => ({ default: m.OnboardingPage })));
const Profile = lazy(() => import('modules/profile').then((m) => ({ default: m.Profile })));
const CreateAttachmentPage = lazy(() =>
    import('modules/attachments/components/CreateAttachmentPage').then((m) => ({ default: m.CreateAttachmentPage })),
);
const EditAttachmentPage = lazy(() =>
    import('modules/attachments/components/EditAttachmentPage').then((m) => ({ default: m.EditAttachmentPage })),
);
const AttachmentsUploadPage = lazy(() =>
    import('modules/attachments/components/upload/AttachmentsUploadPage').then((m) => ({
        default: m.AttachmentsUploadPage,
    })),
);
const DashboardPage = lazy(() =>
    import('modules/dashboard/components/DashboardPage').then((m) => ({ default: m.DashboardPage })),
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <Authenticated />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: config.routes.auth.logout,
                element: <LogoutPage />,
            },
            {
                path: config.routes.commissions.list,
                element: <CommissionsPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.commissions.detail,
                element: (
                    <SelectedDispatchersProvider>
                        <CommissionsDetailPage />
                    </SelectedDispatchersProvider>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.commissions.new,
                element: (
                    <SelectedDispatchersProvider>
                        <CommissionsNewPage />
                    </SelectedDispatchersProvider>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.commissions.export,
                element: <CommissionsExport />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.carriers.list,
                element: <CarriersPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.carriers.detail,
                element: <CarrierDetailPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.carriers.new,
                element: <CarriersNewPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.customers.list,
                element: <CustomersPage />,
            },
            { path: config.routes.customers.detail, element: <CustomersDetailPage />, errorElement: <ErrorPage /> },
            { path: config.routes.customers.new, element: <CustomersNewPage />, errorElement: <ErrorPage /> },
            {
                path: config.routes.invoicing.list,
                element: <InvoicingPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.invoicing.detail,
                element: <InvoicingDetailPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.invoicing.new,
                element: <InvoicingNewPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.offers.list,
                element: <OffersPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.offers.detail,
                element: (
                    <SelectedDispatchersProvider>
                        <MapProvider>
                            <OffersDetailPage />
                        </MapProvider>
                    </SelectedDispatchersProvider>
                ),
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.dispatcher.detail,
                element: <DispatcherDetailPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.dispatcher.new,
                element: <DispatcherNewPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.profile,
                element: <Profile />,
                errorElement: <ErrorPage />,
            },
            {
                path: config.routes.attachments.detail,
                element: <EditAttachmentPage />,
            },
            {
                path: config.routes.attachments.new,
                element: <CreateAttachmentPage />,
            },
            {
                path: config.routes.dashboard.dashboard,
                element: <DashboardPage />,
            },
        ],
    },
    { path: config.routes.dispatcher.onboarding, element: <OnboardingPage />, errorElement: <ErrorPage /> },
    {
        path: config.routes.attachments.upload,
        element: <AttachmentsUploadPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: config.routes.auth.login,
        element: <LoginForm />,
        errorElement: <ErrorPage />,
    },
]);

export const Application = () => (
    <Sentry>
        <Provider store={store}>
            <I18nProvider locale="cs-CZ">
                <Suspense fallback={<Spinner fullScreen />}>
                        <RouterProvider router={router} />
                </Suspense>
            </I18nProvider>
        </Provider>
    </Sentry>
);
