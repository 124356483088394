import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { extractValuesFromObject } from 'utils/extractValuesFromObject';

import { baseQueryWithAuthRefresh } from './baseQuery';

export const GeneralApiTagTypes = {
    invoice: {
        list: 'Invoices',
        item: 'InvoiceItem',
        pdf: 'InvoicePDF',
        numbers: 'InvoiceNumbers',
        invoicingStatusCounts: 'InvoicingStatusCounts',
        invoicingStatuses: 'InvoicingStatuses',
        commissionsForInvoicing: 'CommissionsForInvoicing',
    },
    attachment: {
        list: 'Attachments',
        item: 'Attachment',
        file: 'AttachmentFile',
        uploadLink: 'AttachmentUploadLink',
    },
    user: {
        list: 'Users',
    },
    metrics: {
        get: 'Metrics',
    },
} as const;

export const GeneralApiTagTypesArray = extractValuesFromObject(GeneralApiTagTypes);

export const generalApi = createApi({
    baseQuery: baseQueryWithAuthRefresh,
    tagTypes: GeneralApiTagTypesArray,
    endpoints: () => ({}),
});
