import React, { DetailedHTMLProps, forwardRef, TextareaHTMLAttributes, useLayoutEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import classNames from 'classnames';

import styles from './Textarea.module.scss';

export interface ITextareaProps
    extends Omit<DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'ref'> {
    error?: boolean;
    autoSizing?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
    ({ className, error, autoSizing = true, ...props }, ref) => {
        const innerRef = useRef<HTMLTextAreaElement>(null);

        // Auto-resizing textarea
        useLayoutEffect(() => {
            if (!innerRef.current || !autoSizing) return;
            innerRef.current.style.height = 'inherit'; // reset výšky
            innerRef.current.style.height = `${innerRef.current.scrollHeight}px`; // nastaví výšku podle scrollHeight
        }, [props.value, autoSizing]);

        return (
            <textarea
                ref={mergeRefs([innerRef, ref])}
                rows={1}
                className={classNames(
                    styles.textarea,
                    {
                        [styles.error]: !!error,
                    },
                    className,
                )}
                {...props}
            />
        );
    },
);

Textarea.displayName = 'Textarea';
