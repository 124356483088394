import { NavLink } from 'react-router-dom';
import { LogoSquareIcon } from 'assets/icons';
import classNames from 'classnames';
import { config } from 'config';
import { useAuth } from 'core/auth';
import { AuthRole } from 'core/auth/types';
import { useNonTypedTranslation } from 'core/translation';
import { Container } from 'modules/ui/components/Container';

import styles from './Header.module.scss';

export interface HeaderProps extends React.ComponentProps<'header'> {
    hideNavigation?: boolean;
}

export const Header = ({ children, hideNavigation = false }: HeaderProps) => {
    const { tnt } = useNonTypedTranslation();
    const { user } = useAuth();

    const getAvailableRoutes = (role: AuthRole) => {
        switch (role) {
            case AuthRole.USER: {
                return [
                    [config.nav.dashboard, config.routes.dashboard.dashboard],
                    [config.nav.commissions, config.routes.commissions.list],
                    [config.nav.carriers, config.routes.carriers.list],
                    [config.nav.customers, config.routes.customers.list],
                    [config.nav.invoicing, config.routes.invoicing.list],
                    [config.nav.offers, config.routes.offers.list],
                ];
            }
            case AuthRole.DISPATCHER: {
                return [
                    [config.nav.offers, config.routes.offers.list],
                    [config.nav.profile, config.routes.profile],
                ];
            }
            default: {
                return [];
            }
        }
    };

    return (
        <header className={styles.header}>
            <Container className={styles.container} fullWidth>
                <nav className={styles.nav}>
                    <LogoSquareIcon />
                    {!hideNavigation && (
                        <ul className={styles['nav-list']}>
                            {user?.role &&
                                getAvailableRoutes(user.role).map(([title, path]) => (
                                    <li className={styles['nav-list-item']} key={path}>
                                        <NavLink
                                            className={({ isActive, isPending }) =>
                                                classNames(styles['nav-link'], {
                                                    [styles['active']]: isActive || isPending,
                                                })
                                            }
                                            to={path}
                                        >
                                            {tnt(`nav.${title}`)}
                                        </NavLink>
                                    </li>
                                ))}
                        </ul>
                    )}
                </nav>
                <div className={styles.info}>{children}</div>
            </Container>
        </header>
    );
};
