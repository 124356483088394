import { Language } from 'core/translation/i18next/i18next';
import { i18n } from 'i18next';
import { Currency } from 'modules/common';

import { getLocaleByLanguage } from './locale';

const EMPTY_PLACEHOLDER = '-';

export type TFormatPriceArgs = {
    roundingMethod?: 'floor' | 'ceil' | 'round' | 'truncate' | 'none';
    numberOfDigits?: number;
    lang?: Language;
    currency?: Currency;
};

/**
 * @param value - number to format
 * @param i18n - i18n instance
 * @param args - additional arguments
 * @param args.numberOfDigits - number of digits after decimal point (default 2)
 * @param args.roundingMethod - method of rounding the number (default none)
 * @param args.currency - currency to display (default none)
 * @param args.lang - language to display (default i18n), if not provided, i18n language is used
 * @returns
 */
export const formatNumber = (
    value: number | null | undefined,
    i18n: i18n,
    { numberOfDigits = 2, roundingMethod, currency, lang = Language.cs }: TFormatPriceArgs = {},
) => {
    if (value === undefined || value === null) {
        return EMPTY_PLACEHOLDER;
    }
    const rounded = (() => {
        switch (roundingMethod) {
            case 'floor':
                return Math.floor(value);
            case 'round':
                return Math.round(value);
            case 'truncate':
                return Math.trunc(value);
            case 'ceil':
                return Math.ceil(value);
            case 'none':
            default:
                return value;
        }
    })();
    const formatted = rounded.toLocaleString(getLocaleByLanguage(lang || i18n.language), {
        minimumFractionDigits: numberOfDigits,
        maximumFractionDigits: numberOfDigits,
    });

    if (currency) {
        // unbeatable space
        return `${formatted} ${i18n.t(`common.currency.${currency}`)}`;
    }

    return formatted;
};
